import React, { useEffect, useState } from "react";
import notification from "../../images/notification.png";
import downloadLink from "../../images/download-icon.svg";
import arrowRight from "../../images/arrow-bright.svg";
import arrowLeft from "../../images/back-arrow-blue.svg";
import contact from "../../images/contact.svg";
import tick from "../../images/tick.png";
import cross from "../../images/x 1.png";
import DeleteConfirmation from "../custom/delete-confirmation/DeleteConfirmation";
import useAxios from "../../axiosinstance";
import { useSelector, useDispatch } from "react-redux";
import { getTimeDifference } from "../../utilities/DateFormate";
import { useNavigate } from "react-router-dom";
import useTranslation from "../customHooks/translations";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import clip from "../../images/clipboard 1.svg";
import documentIcon from "../../images/file 2.svg";
import consentIcon from "../../images/briefcase 1.svg";
import messageIcon from "../../images/message-circle 1.svg";
import componentIcon from "../../images/Component 1.svg";
import appointmentIcon from "../../images/calendar 1.svg";
import { notificationRef } from "../../redux/actions";
import { Replace_New_Appointment, Replace_New_Message, Replace_Patient_Added, Replace_PROMs_questionnaire, Replace_Requested_Message, Replace_Shared_Document } from "../../utilities/NotificationLocalazation";
function Notifications() {
  const login = useSelector((state) => state.loginPatient.arr[0]);
  let notification_data = useSelector((state) => state.notification.arr);
  const axiosInstance = useAxios();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const translation = useTranslation();
  const [clickedIndex, setClickedIndex] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [getNotificationData, setNotificationData] = useState([]);
  const [notiId, setNotiId] = useState("");
  const [formType, setFormType] = useState(null);
  const [sender, setSender] = useState("");
  const [reff, setReff] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [offset, setOffset] = useState(10);
  const [limit, setLimit] = useState(10);
  const handleClick = (index) => {
    setClickedIndex(index);
  };

  const hideConfirmationModal = () => {
    setShowModal(false);
  };

  const handleOne = (id) => {
    setNotiId(id);
    setShowModal(true);
  };

  const getNotification = async () => {
    try {
      const response = await axiosInstance.current.get(
        `extapp/patients/get_notifications?ak_id=${
          login.patient.ak_id
        }&check=&limit=${limit}&offset=${0}`
      );
      if (response.status === 200) {
        setNotificationData(response.data.data);
        sessionStorage.setItem("notificationData", response.data.total_data);
      }
    } catch (err) {
      setNotificationData([]);
      sessionStorage.setItem("notificationData", "");
      dispatch(notificationRef([]))

    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (login.patient.ak_id) {
      getNotification();
    }
  }, [login.patient.ak_id, reff]);

  const addMoreData = async () => {
    setOffset(offset + 10);
    try {
      const response = await axiosInstance.current.get(
        `extapp/patients/get_notifications?ak_id=${
          login.patient.ak_id
        }&check=&limit=${limit}&offset=${0}`
      );
      const resdata = response.data.data;
      setTimeout(() => {
        setNotificationData(getNotificationData.concat(resdata));
      }, 500);
    } catch (err) {}
  };
  const fetchMoreData = () => {
    addMoreData();
  };

  useEffect(() => {
    fetchMoreData();
  }, []);
  const handleRedirect = (type, url) => {
    if (type.redirect_type === 1) {
      window.location.href = url;
    } else if (type.redirect_type === 2) {
      navigate("/messages");
    } else if (type.redirect_type === 5) {
      navigate("/document");
    } else if (type.redirect_type === 6) {
      navigate("/my-consent");
    } else if (type.redirect_type === 3) {
      navigate("/upcoming-visits");
    }
  };
  const markAsRead = async (type) => {
    let speciality = type?.notify_message?.speciality || "";

    if (speciality === 24) {
      speciality = 5;
    }

    setFormType(speciality);
    const sender = type?.sender || "";
    const resouceId = type?.resource_id || "";
    let url =
      process.env.REACT_APP_FORM_URL +
      "patient-personal-data" +
      `/${window.btoa(login.patient.ak_id)}` +
      `/${window.btoa(speciality) || ""}?r=${window.btoa(resouceId)}`;
    handleRedirect(type, url);
    
    const json = JSON.stringify({
      _id: type._id,
      ak_id: login.patient.ak_id,
    });

    try {
      const response = await axiosInstance.current.put(
        `extapp/patients/seen_notification`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        setReff(new Date().getMilliseconds());
        dispatch(notificationRef(notification_data-1))
      }
    } catch (err) {
      // handleRedirect(type, url);
    }
  };

  let id = notiId || "";

  const deleteNotification = async () => {
    try {
      const response = await axiosInstance.current.delete(
        `extapp/patients/delete_notification?ak_id=${login.patient.ak_id}&_id=${id}`
      );
      if (response.status === 200) {
        // dispatch(setRefRedux(new Date().getMilliseconds()));
        dispatch(notificationRef(notification_data - 1));
        setReff(new Date().getMilliseconds());
        setShowModal(false);
      }
    } catch (err) {}
  };
 
// useEffect(()=>{
// if(getNotificationData.length===0){
// dispatch(notificationRef([]))
// setReff(new Date().getMilliseconds());
// }
// },[])
  return (
    <div>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="notification_container_inner">
            <div className="notification_header d-flex align-items-center">
              <div className="d-inline-block notification_title">
                <h3>{translation.Notifications}</h3>
              </div>
              {getNotificationData.length !== 0 ? (
                <>
                  <button
                    className="LinkBtn1"
                    style={{ width: "140px" }}
                    onClick={markAsRead}
                  >
                    {translation.Mark_all_read}
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
            {getNotificationData.length !== 0 ? (
              <>
                <div class="whitebox ps-0">
                  <div className="mt-4 m-lg-4">
                    {getNotificationData.map((column, index) => (
                      <>
                        <div
                          key={index}
                          style={{ width: "100%" }}
                          className={`notify_item1 d-flex ${
                            clickedIndex === index
                              ? "notify_msg_read"
                              : "notify_msg_read"
                          }`}
                          onClick={() => handleClick(column)}
                        >
                          <div
                            style={{
                              width: "95%",
                              backgroundColor:
                                column.check === true ? "white" : "",
                            }}
                            className={`notify_item d-flex align-items-center  ${
                              clickedIndex === index
                                ? "notify_msg_unread"
                                : "notify_msg_unread"
                            }`}
                            onClick={() => markAsRead(column)}
                          >
                            <div className="d-flex align-items-center ms-3 m-lg-0">
                              <div className="me-3">
                                {column.redirect_type === 1 && (
                                  <img
                                    src={clip}
                                    alt="img"
                                    style={{
                                      height: "22%",
                                      width: "22px",
                                      marginLeft: "5px",
                                      marginRight: "0px",
                                    }}
                                  />
                                )}
                                {column.redirect_type === 2 && (
                                  <img
                                    src={messageIcon}
                                    alt="img"
                                    style={{
                                      height: "22%",
                                      width: "22px",
                                      marginLeft: "5px",
                                      marginRight: "0px",
                                    }}
                                  />
                                )}
                                {column.redirect_type === 3 && (
                                  <img
                                    src={appointmentIcon}
                                    alt="img"
                                    style={{
                                      height: "22%",
                                      width: "22px",
                                      marginLeft: "5px",
                                      marginRight: "0px",
                                    }}
                                  />
                                )}
                                {column.redirect_type === 5 && (
                                  <img
                                    src={documentIcon}
                                    alt="img"
                                    style={{
                                      height: "22%",
                                      width: "22px",
                                      marginLeft: "5px",
                                      marginRight: "0px",
                                    }}
                                  />
                                )}
                                {column.redirect_type === 6 && (
                                  <img
                                    src={consentIcon}
                                    alt="img"
                                    style={{
                                      height: "22%",
                                      width: "22px",
                                      marginLeft: "5px",
                                      marginRight: "0px",
                                    }}
                                  />
                                )}
                                {column.redirect_type === 7 && (
                                  <img
                                    src={componentIcon}
                                    alt="img"
                                    style={{
                                      height: "22%",
                                      width: "22px",
                                      marginLeft: "5px",
                                      marginRight: "0px",
                                    }}
                                  />
                                )}
                              </div>
                              <div className="me-3">
                                {column.redirect_type === 1 && (
                                  <>
                                    <strong
                                      className="notify_msg d-inline"
                                      style={{
                                        marginLeft: 10,
                                        fontWeight: 500,
                                      }}
                                    >
                                      {Replace_PROMs_questionnaire(
                                        translation.PROMs_questionnaire,
                                        "Dr. " +
                                          column.notify_message.firstname +
                                          " " +
                                          column.notify_message.lastname,
                                        column.notify_message.speciality === 22
                                          ? translation.Elbow
                                          : column.notify_message.speciality ===
                                            4
                                          ?  translation.shoulder
                                          : column.notify_message.speciality ===
                                            6
                                          ? translation.hip_form
                                          : column.notify_message.speciality ===
                                            21
                                          ? translation.Foot_And_Ankle
                                          : column.notify_message.speciality ===
                                            5
                                          ? translation.adolescent_knee
                                          : column.notify_message.speciality ===
                                            20
                                          ?  translation.paediatric_knee
                                          : column.notify_message.speciality ===
                                            24
                                          ?  translation.adult_knee
                                          : column.notify_message.speciality ===
                                            23
                                          ? translation.Hand_And_Wrist
                                          : column.notify_message.speciality ===
                                            33
                                          ?  translation.General
                                          : "" + " "
                                      )}
                                    </strong>
                                  </>
                                )}
                                {column.redirect_type === 2 && (
                                  <>
                                    <strong
                                      style={{
                                        fontWeight: 500,
                                        marginLeft: 10,
                                        color: "#3f2783",
                                      }}
                                    >
                                     

                                     {Replace_New_Message(translation.new_message, "Dr. " +
                                          column.notify_message.firstname +
                                          " " +
                                          column.notify_message.lastname)}
                                    </strong>
                                   
                                    
                                  </>
                                )}
                                {column.redirect_type === 3 && (
                                  <>
                                    <strong
                                      style={{
                                        fontWeight: 500,
                                        marginLeft: 10,
                                        color: "#3f2783",
                                      }}
                                    >
                                    
                                      {Replace_New_Appointment(translation.new_appointment,"Dr. " +
                                          column.notify_message.firstname +
                                          " " +
                                          column.notify_message.lastname)}
                                    </strong>
                                   
                                  </>
                                )}
                                
                                {column.redirect_type === 7 && (
                                  <>
                                    <strong
                                      style={{
                                        fontWeight: 500,
                                        marginLeft: 10,
                                        color: "#3f2783",
                                      }}
                                    >
                                     {Replace_Patient_Added(translation.patient_added_message,"Dr. " +
                                          column.notify_message.firstname +
                                          " " +
                                          column.notify_message.lastname,column.notify_message.org_name)}
                                     
                                    </strong>
                                    
                                  </>
                                )}
                               
                                {column.redirect_type === 6 && (
                                  <>
                                  
                                         
                                    <strong
                                      className="notify_msg d-inline"
                                      style={{
                                        marginLeft: 10,
                                        fontWeight: 500,
                                      }}
                                    >
                                      {Replace_Requested_Message(translation.requested_message,"Dr. " +
                                          column.notify_message.firstname +
                                          " " +
                                          column.notify_message.lastname)}
                                    </strong>
                                  </>
                                )}
                             
                                {column.redirect_type === 5 && (
                                  <>
                                 
                                    <strong
                                      style={{
                                        fontWeight: 500,
                                        marginLeft: 7,
                                        color: "#3f2783",
                                      }}
                                    >
                                       {Replace_Shared_Document(translation.document_message,"Dr. " +
                                          column.notify_message.firstname +
                                          " " +
                                          column.notify_message.lastname)}
                                    </strong>
                                  </>
                                )}

                                <div className="notification_type">
                                  <div className="notify_msg">
                                    <strong
                                      style={{
                                        fontWeight: 500,
                                        marginLeft: 10,
                                        color: "#3f2783",
                                      }}
                                    >
                                      {getTimeDifference(column.created_at)}
                                    </strong>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="right_icon d-flex">
                              <img
                                src={arrowRight}
                                alt="img"
                                className="d-flex notify_item_right"
                                onClick={() => markAsRead(column)}
                              />
                            </div>
                          </div>

                          <img
                            src={cross}
                            alt="img"
                            className="cross_icon d-flex"
                            style={{ marginLeft: "auto", marginRight: 10 }}
                            onClick={() => handleOne(column._id)}
                          />
                        </div>
                      </>
                    ))}
                  </div>

                  <div className="d-flex justify-content-center align-items-center">
                    <button
                      className="LinkBtn1"
                      style={{ alignContent: "center" }}
                      onClick={() => handleOne("")}
                    >
                      {translation.Delete_All}
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="panel-body p_25">
                  <div className="no_data">{translation.No_Notification}</div>
                </div>
              </>
            )}
          </div>
        </>
      )}
      <DeleteConfirmation
        showModal={showModal}
        confirmModal={deleteNotification}
        hideModal={hideConfirmationModal}
      />
    </div>
  );
}

export default Notifications;
